<i18n>
	{
	"en": {
			"editJob": "Edit job details"
		},
	"fi": {
			"editJob": "Muokkaa työtietoa"
		}
	}
</i18n>

<template>
	<v-container
		v-if="ready === false"
		class="container--narrow"
	>
		<Spinner />
	</v-container>
	<v-container
		v-else
		class="container--narrow"
	>
		<v-card>
			<v-card-title>
				{{ $i18n.t('editJob') }}
			</v-card-title>
			<v-spacer />
			<v-card-text>
				<SchemaToForm
					:debug="false"
					:schema="config.forms.jobs"
					:disabled="formLoading"
					v-model="formData"
					@valid="formValid = true"
					@invalid="formValid = false"
					@change="$store.commit('setConfirmNavigation', true)"
				/>
			</v-card-text>
			<v-divider />
			<v-card-actions>
				<v-spacer />
				<v-btn
					color="secondary"
					:loading="formLoading"
					:disabled="formLoading|| !formValid"
					@click="save"
					text
				>
					{{ $i18n.t('general.save') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>
import SchemaToForm from '@/components/SchemaToForm'
import { mapState } from 'vuex'

export default {
	name: 'JobEdit',

	components: {
		SchemaToForm,
	},

	data: () => ({
		ready: false,
		job: null,
		formLoading: false,
		formSchema: {},
		formData: {},
		formValid: false,
	}),

	computed: {
		...mapState([
			'user',
			'config',
		]),
	},

	mounted () {
		this.loadJob()

		this.$nextTick(() => {
			this.ready = true
		})
	},

	methods: {
		loadJob () {
			this.job = this.user._jobs.find(job => {
				return job.name == this.$route.params.pagename
			})

			// Set form data
			this.formData = this.job

			if (!this.job) {
				this.$router.replace({ name: 'Error404' })
			}
		},

		save () {
			this.formLoading= true

			this.$api.Me.doRequest({
				method: 'POST',
				url: 'job-edit',
				body: {
					...this.formData,
					id: this.job.id,
				},
			}).on('done', res => {
				if (!res.body.error) {
					this.$nextTick(() => {
						this.$store.dispatch('clearConfirmNavigation')
						this.$router.replace({
							name: this.$route.matched[this.$route.matched.length - 2].name,
						})
					})
				}
			}).on('finish', () => {
				this.formLoading= false
			})
		},
	},
}
</script>
