<i18n>
{
  "en": {
		"tabs": {
			"news": {
				"title": "News",
				"noItemsText": "No news to show."
			},
			"news": {
				"title": "Open jobs",
				"noItemsText": "No open jobs to show."
			}
		}
	},
  "fi": {
		"tabs": {
			"news": {
				"title": "Uutiset",
				"noItemsText": "Uutisia ei löytynyt."
			},
			"jobs": {
				"title": "Avoimet työpaikat",
				"noItemsText": "Avoimia työpaikkoja ei löytynyt."
			}
		}
	}
}
</i18n>

<template>
	<div>
		<!-- Output child page -->
		<router-view />

		<!-- Output navigation if we're not on child page -->
		<template v-if="$route.name == 'newsContainer'">
			<v-toolbar
				color="primary"
				dark
				flat
			>
				<v-tabs
					v-model="currentTab"
					centered
					center-active
					show-arrows
				>
					<v-tabs-slider />
					<v-tab
						v-for="(item, index) in tabs"
						:key="'tab-' + index"
					>
						{{ $i18n.t('tabs.' + item + '.title') }}
					</v-tab>
				</v-tabs>
			</v-toolbar>
			<v-tabs-items v-model="currentTab">
				<!-- News -->
				<v-tab-item>
					<v-container class="container--narrow">
						<FilterableList
							:items="news"
							subtitle-src="date"
							:meta-src="null"
							redirect-url-src="link"
							:no-items-text="$i18n.t('tabs.news.noItemsText')"
							@itemClick="item => $router.push({ name: 'news', params: { pagename: item.name } })"
						/>
					</v-container>
				</v-tab-item>

				<!-- Open jobs -->
				<v-tab-item>
					<v-container class="container--narrow">
						<FilterableList
							:items="jobs"
							subtitle-src="date"
							:meta-src="null"
							redirect-url-src="link"
							:no-items-text="$i18n.t('tabs.jobs.noItemsText')"
							@itemClick="item => $router.push({ name: 'news', params: { pagename: item.name } })"
						/>
					</v-container>
				</v-tab-item>
			</v-tabs-items>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'NewsContainer',
	data: () => ({
		tabs: [
			'news',
			'jobs',
		],
		currentTab: 0,
	}),
	computed: {
		...mapState({
			news: state => state.news.news,
			jobs: state => state.news.jobs,
		}),
	},
	mounted () {
		this.$api.News.doRequest()
	},
}
</script>
